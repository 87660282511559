import { useNavigate, useSearchParams } from 'react-router';
import { useCallback } from 'react';

export default function useRouteTo() {
  const navigate = useNavigate(),
        [currentSearchParams] = useSearchParams();

  /**
   * Navigates to the given pathname. This is simply a wrapper around react-router `navigate` function.
   * If given, search params are merged with existing search params so that global filters are always kept.
   *
   * @param pathname {string} The target route
   * @param [params] {object} Optional search params
   * @param [options] {object} Options given to the navigate function
   */
  function routeTo(pathname, params, options) {
    const newSearchParams = new URLSearchParams(params),
          searchParams = new URLSearchParams(currentSearchParams);

    // Keep existing search params
    newSearchParams.forEach((value, name) => searchParams.set(name, value));

    return navigate({ pathname, search: searchParams.toString() }, options);
  }

  return useCallback(routeTo, [navigate, currentSearchParams]);
}
