'use strict';

const stringComparator = require('./comparators/stringComparator.cjs'),
      numberComparator = require('./comparators/numberComparator.cjs');

const ALL = '*';

/**
 * @typedef {object} SecurityPermission
 * @property {number} id The permission identifier
 * @property {'*'|string[]} features The permission features
 * @property {'*'|string[]} privileges The privileges given by the permission
 * @property {'*'|number[]} items The identifiers of the items impacted by the permission
 * @property {'*'|string[]} fields The fields impacted by the permission
 */
/**
 * @typedef {object} SecurityRole
 * @property {number} id The role identifier
 * @property {string} name the role name
 * @property {SecurityPermission[]} securityPermissions The permissions associated to the role
 */
/**
 * @typedef {object} User
 * @property {number} id The user identifier
 * @property {SecurityRole[]} securityRoles The user's roles
 */

/**
 * Checks whether a user has a specific permission.
 * This function starts by isolating all permissions matching the requested parameters, performs some merging to group
 * similar permissions' privileges, then finds the "best match" permission, i.e.: the most specific one for the set of given
 * parameters, before finally checking the granted privileges.
 *
 * @param user {User} The user to check permissions for
 * @param feature {string} The desired feature
 * @param privilege {string} The desired privilege
 * @param [item] {number|string} The target item ID, if applicable
 * @param [field] {string} The target field name, if applicable
 *
 * @returns {boolean} Whether the user has the requested permission.
 */
module.exports = function userHasPermission(user, feature, privilege, item, field) {
  const permissions = [];

  for (const role of user.securityRoles) {
    for (const permission of role.securityPermissions) {
      if (permission.features === ALL || permission.features.includes(feature)) {
        if (permission.items === ALL || permission.items.includes(+item)) { // Casting is a bit ugly here
          if (permission.fields === ALL || permission.fields.includes(field)) {
            const key = keyOf(permission),
                  existing = permissions[key];

            if (!existing) {
              const store = {
                features: permission.features,
                privileges: permission.privileges,
                items: permission.items,
                fields: permission.fields
              };

              permissions.push(store);
              permissions[key] = store;
            } else {
              existing.privileges = merge(existing.privileges, permission.privileges);
            }
          }
        }
      }
    }
  }

  if (!permissions.length) {
    return false;
  }

  const [bestMatchPermission] = permissions.sort(weightedAllLast);

  return bestMatchPermission.privileges === ALL || bestMatchPermission.privileges.includes(privilege);
};

//

function allLast(a, b, on, weight) {
  if (a[on] === ALL) {
    return b[on] === ALL ? 0 : weight;
  }

  return b[on] === ALL ? -weight : 0;
}

function weightedAllLast(a, b) {
  return allLast(a, b, 'features', 100) + allLast(a, b, 'items', 10) + allLast(a, b, 'fields', 1);
}

function keyOf(permission) {
  function serialize(value, comparator = stringComparator) {
    if (value === ALL) {
      return value;
    }

    return value.sort(comparator); // Sort values so that ['user', 'brand'][1, 2]* is equal to ['brand', 'user'][2, 1]*
  }

  return serialize(permission.features) + serialize(permission.items, numberComparator) + serialize(permission.fields);
}

function merge(a, b) {
  if (a === ALL || b === ALL) {
    return ALL;
  }

  return a.concat(b); // Might include duplicates, but that's ok for checking permissions
}
