import { useHref } from 'react-router';
import Link from '@mui/material/Link';
import { forwardRef } from 'react';

import ClientSideLink from '../ClientSideLink/index.jsx';

function AutoRelativeOrAbsoluteLink({ to, children, ...rest }, ref) {
  const base = useHref('/'),
        absoluteBase = new URL(base, window.location).href,
        absoluteHref = new URL(to, absoluteBase).href;

  if (absoluteHref.startsWith(absoluteBase)) {
    return (
      <ClientSideLink to={absoluteHref} {...rest} ref={ref}>
        {children}
      </ClientSideLink>
    );
  }

  return (
    <Link href={absoluteHref} rel={'noopener'} target={'_blank'} {...rest} ref={ref}>
      {children}
    </Link>
  );
}

export default forwardRef(AutoRelativeOrAbsoluteLink);
