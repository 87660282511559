import { ErrorBoundary } from 'react-error-boundary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useRef } from 'react';

import refresh from '../../services/refresh/index.js';
import ButtonBar from '../ButtonBar/index.jsx';
import useEffectOnChange from '../../services/useEffectOnChange/index.js';

function ErrorComponent({ error, resetErrorBoundary, Error404Page, Error403Page }) {
  const { t: __ } = useTranslation(),
        location = useLocation(),
        locationOnMount = useRef(location);

  useEffectOnChange(resetErrorBoundary, [location], [locationOnMount.current]);

  if (Error404Page) {
    if (error?.response?.status === 404) {
      return <Error404Page />;
    }
  }

  if (Error403Page) {
    if (error?.response?.status === 403) {
      return <Error403Page />;
    }
  }

  return (
    <Card>
      <CardContent>
        <Alert severity="warning">
          {__('An error occured')}
          <Box>
            <Typography variant={'caption'}>{__('You can try again by reloading the page. If the problem persists, please contact Stordata support')}</Typography>
          </Box>
          <Box data-testid="error" marginTop={2}>{error.message}</Box>
        </Alert>
      </CardContent>
      <CardActions>
        <ButtonBar>
          <Button onClick={refresh}>{__('Reload the page')}</Button>
        </ButtonBar>
      </CardActions>
    </Card>
  );
}

export default function RetryableErrorBoundary({ Error404Page, Error403Page, children }) {
  return (
    <ErrorBoundary fallbackRender={props => <ErrorComponent Error404Page={Error404Page} Error403Page={Error403Page} {...props} />}>
      {children}
    </ErrorBoundary>
  );
}
