'use strict';

const first = require('./first.cjs'),
      aggregationOf = require('./aggregationOf.cjs'),
      thresholdHasTarget = require('./thresholdHasTarget.cjs'),
      thresholdHasPreset = require('./thresholdHasPreset.cjs'),
      qualifiedFieldName = require('./qualifiedFieldName.cjs');

module.exports = function patchThresholdWithDynamicValue(threshold, thresholdsValues, name = threshold.name) {
  if (!thresholdsValues) {
    return threshold;
  }

  if (!thresholdHasTarget(threshold)) {
    if (!thresholdHasPreset(threshold)) {
      return threshold;
    }

    const [warning, alert] = thresholdsValues[threshold.preset.variable];

    return {
      ...threshold,
      value: name === 'alert' ? alert : warning
    };
  }

  const [field, metric] = threshold.target.metric,
        qualifiedTarget = qualifiedFieldName(field, aggregationOf(metric));

  if (!(qualifiedTarget in thresholdsValues)) {
    return threshold;
  }

  const thresholdValue = thresholdsValues[qualifiedTarget],
        applyPercentOn = v => (first(v) * threshold.target.percent) / 100;

  if ('breakdown' in thresholdValue) {
    return {
      ...threshold,
      breakdown: thresholdValue.breakdown.map(bucket => ({
        key: bucket.key,
        value: applyPercentOn(bucket.value)
      }))
    };
  }

  return {
    ...threshold,
    value: applyPercentOn(thresholdValue.value)
  };
};
