'use strict';

/**
 * Generates a composite breakdown key.
 * The key is a string composed of point values for each level of the breakdown.
 *
 * @param point {object} A data point
 * @param breakdown {{field: string}[]} The multi-breakdown definition
 *
 * @returns {string} The breakdown key
 */
module.exports = function breakdownKey(point, breakdown) {
  return breakdown.map(definition => point[definition.field]).join(',');
};
