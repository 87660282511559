const CONTROLS = require('./controls.cjs');

function evalValueOrArray(target, handler) {
  if (Array.isArray(target)) {
    return target.some(handler);
  }

  return handler(target);
}

function before(value, threshold, parser) {
  return evalValueOrArray(value, v => parser(v) < threshold);
}

function beforeOrEqual(value, threshold, parser) {
  return evalValueOrArray(value, v => parser(v) <= threshold);
}

function itIs(value, threshold) {
  return evalValueOrArray(value, v => evalValueOrArray(threshold, t => v === t)); // Support arrays and single threshold values
}

function moreThan(value, threshold) {
  return evalValueOrArray(value, v => v > threshold);
}

function itContains(value, threshold) {
  return evalValueOrArray(value, v => v.includes(threshold));
}

function moreThanOrEqualTo(value, threshold) {
  return evalValueOrArray(value, v => v >= threshold);
}

function ifNotEmpty(handler) {
  return function callHandlerIfNotEmpty(value, threshold, parser) {
    if (!exists(value)) {
      return false;
    }

    return handler(value, threshold, parser);
  };
}

function exists(value) {
  return value !== null && value !== undefined && !isEmptyArray(value);
}

function isEmptyArray(value) {
  return Array.isArray(value) && value.length === 0;
}

function doesNotExist(value) {
  return !exists(value);
}

function changedFromPrevious([current, previous]) {
  if (!exists(current) || !exists(previous)) { // We need both values to perform the comparison
    return false;
  }

  if (current.length !== previous.length) {
    return true;
  }

  return current.some((value, i) => value !== previous[i]);
}

const controls = {
  [CONTROLS.EXISTS]: exists,
  [CONTROLS.DOES_NOT_EXIST]: doesNotExist,
  [CONTROLS.IS]: ifNotEmpty(itIs),
  [CONTROLS.IS_NOT]: ifNotEmpty((value, threshold) => !itIs(value, threshold)),
  [CONTROLS.MORE_THAN]: ifNotEmpty(moreThan),
  [CONTROLS.MORE_THAN_OR_EQUAL]: ifNotEmpty(moreThanOrEqualTo),
  [CONTROLS.LESS_THAN]: ifNotEmpty((value, threshold) => !moreThanOrEqualTo(value, threshold)),
  [CONTROLS.LESS_THAN_OR_EQUAL]: ifNotEmpty((value, threshold) => !moreThan(value, threshold)),
  [CONTROLS.CONTAINS]: ifNotEmpty(itContains),
  [CONTROLS.DOES_NOT_CONTAIN]: ifNotEmpty((value, threshold) => !itContains(value, threshold)),
  [CONTROLS.BEFORE]: ifNotEmpty((value, threshold, parser) => before(value, parser(threshold), parser)),
  [CONTROLS.BEFORE_OR_EQUAL]: ifNotEmpty((value, threshold, parser) => beforeOrEqual(value, parser(threshold), parser)),
  [CONTROLS.AFTER]: ifNotEmpty((value, threshold, parser) => !beforeOrEqual(value, parser(threshold), parser)),
  [CONTROLS.AFTER_OR_EQUAL]: ifNotEmpty((value, threshold, parser) => !before(value, parser(threshold), parser)),
  [CONTROLS.CHANGED_FROM_PREVIOUS]: changedFromPrevious
};

/**
 * Checks if a given value matches a threshold.
 *
 * @param value {any} The value to check
 * @param threshold {object} The definition of the threshold to match
 * @param [threshold.control=CONTROLS.MORE_THAN] The threshold control, used to select a matching function
 * @param [threshold.value] {number} The threshold value to compare. This is optional and absent when threshold uses a target metric
 * @param [threshold.breakdown] {{key: string, value: number}[]} When threshold uses a target metric, and this metric is
 *  broken down, this holds the breakdown of all available values. The `key` is the bucket key.
 * @param [parser] {function(string): Date} An optional function used to parse dates, so that we can compare them
 * @param [breakdownKey] {string} An optional breakdown key. When used and the threshold uses a target metric, the value is
 *  matched against the corresponding bucket in the list of broken-down values.
 *
 * @returns {boolean} Whether the value matches the threshold.
 */
module.exports = function valueMatchesThreshold(value, threshold, parser, breakdownKey) {
  let thresholdValue = threshold.value;

  if (breakdownKey) {
    const bucketAtKey = threshold.breakdown?.find(bucket => bucket.key === breakdownKey);

    if (bucketAtKey) {
      thresholdValue = bucketAtKey.value;
    }
  }

  return controls[threshold.control || CONTROLS.MORE_THAN](value, thresholdValue, parser);
};
