import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';

import { SnackbarProvider } from '@stordata/material-ui-snackbar-provider';

import '../i18n/index.js';
import queryClient from '../queryClient/index.jsx';
import QueryClientErrorHandler from '../../components/QueryClientErrorHandler/index.jsx';
import UseQueryParamsProvider from '../../components/UseQueryParamsProvider/index.jsx';
import HotkeysProvider from '../../components/HotkeysProvider/index.jsx';

export default function bootstrap({ App, ApplicationContextProvider, homepage }) {
  const root = createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <BrowserRouter basename={homepage}>
        <QueryClientProvider client={queryClient}>
          <UseQueryParamsProvider>
            <ApplicationContextProvider>
              <SnackbarProvider SnackbarProps={{ autoHideDuration: 5000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}>
                <HotkeysProvider>
                  <CssBaseline />
                  <App />
                </HotkeysProvider>
                <QueryClientErrorHandler />
              </SnackbarProvider>
            </ApplicationContextProvider>
          </UseQueryParamsProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  return root;
}
