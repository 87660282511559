import { Route, Routes } from 'react-router';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Suspense } from 'react';

import RetryableErrorBoundary from '@stordata/cmdb-common/src/components/RetryableErrorBoundary/index.jsx';
import Loading from '@stordata/cmdb-common/src/components/Loading/index.jsx';
import useTenantConfiguration from '@stordata/cmdb-common/src/services/useTenantConfiguration/index.js';

import Header from '../Header/index.jsx';
import Footer from '../Footer/index.jsx';
import PasswordResetForm from '../PasswordResetForm/index.jsx';
import LoginTabs from '../LoginTabs/index.jsx';
import PasswordResetConfirmForm from '../PasswordResetConfirmForm/index.jsx';
import ForgetMeConfirmation from '../ForgetMeConfirmation/index.jsx';
import OTPForm from '../OTPForm/index.jsx';
import SignupConfirmForm from '../SignupConfirmForm/index.jsx';
import OauthLoginForm from '../OauthLoginForm/index.jsx';
import OauthConsentForm from '../OauthConsentForm/index.jsx';

import styles from './App.module.scss';

export default function App() {
  const { loginApp: { disableFooter } } = useTenantConfiguration();

  return (
    <Grid container direction="column" className={styles.app}>
      <Grid item className={styles.header}>
        <Header />
      </Grid>
      <Grid item container className={styles.content} alignItems="center" justifyContent="center">
        <Container maxWidth="sm">
          <Suspense fallback={<Loading />}>
            <RetryableErrorBoundary>
              <Routes>
                <Route path={'/reset'} element={<PasswordResetForm />} />
                <Route path={'/reset/confirm'} element={<PasswordResetConfirmForm />} />
                <Route path={'/forget/confirm'} element={<ForgetMeConfirmation />} />
                <Route path={'/otp'} element={<OTPForm />} />
                <Route path={'/signup/confirm'} element={<SignupConfirmForm />} />
                <Route path={'/oauth/:uid'} element={<OauthLoginForm />} />
                <Route path={'/oauth/:uid/consent'} element={<OauthConsentForm />} />
                <Route path={'/*'} element={<LoginTabs />} />
              </Routes>
            </RetryableErrorBoundary>
          </Suspense>
        </Container>
      </Grid>
      {!disableFooter && (
        <Grid item>
          <Footer />
        </Grid>
      )}
    </Grid>
  );
}
